import { Injectable } from '@angular/core'
import { Customer } from './customer';

@Injectable()
export class CustomerService {

    getCustomers() {
        return CUSTOMERS;
    }
}

const CUSTOMERS: Customer[] = [
   {
        name: 'Recopart AB', url: 'http://www.recopart.com/',
        text: `Tillsammans med Recopart AB har vi tagit fram system för att stödja demontering av bilar och försäljning av begagnade delar. 
            Försäljning sker genom flera egenutvecklade system för bilverkstäder, försäkringsbolag och privatpersoner. 
            Medc har sedan 2005 varit drivande utvecklare för detta system.`
    },
    {
        name: 'Randek AB', url: 'https://randek.com',
        text: `Randek är en pionjär inom innovativa automationslösningar med kunder inom prefabricerad hustillverkning. 
        Randek utvecklar och producerar högpresterande maskiner och system för vägg-, golv- och taklinjer och takstolar samt produkter som 
        kapsågar, vändbord och specialmaskiner sedan 1940-talet.`
    },
    {
        name: 'Volvo Bil AB', url: 'https://volvobil.se',
        text: `Volvo Bil AB är en Volvo-återförsäljare som förser anställda på VCC och AB Volvo med tjänstebilar.`
    },
    {
        name: 'Jaramba', url: 'https://www.jaramba.se',
        text: `Ampd AB utvecklar appen Jaramba för barn i åldrarna 2-6. Medc har skrivit bakomvarande API som appen jobbar mot.`
    },
    {
        name: 'Berg & Ström System AB', url: 'http://www.fenix5.se',
        text: `Berg & Ström System AB utvecklar datasystem för bildemonterare där vårt arbete baseras på gedigen kunskap och branscherfarenhet sedan 1984.
            Medc utvecklar bl.a. API mot Orion, som är ett system för att söka och köpa delar och används av de flesta bildemonterarna i Sverige samt laga.se och bildelsbasen.se.`
    }
 ];