import { Component } from '@angular/core';
import { Product } from './product'

@Component({
    selector: 'product-list',
    templateUrl: './product-list.component.html',
    styles: [`
  `]
})
export class ProductListComponent {

    products: Product[]

    constructor() {

        this.products = [{
            name: 'Time',
            url: 'https://time.medc.se',
            text: `
                Projektorienterat tidredovisingssystem för det lilla företaget. Fokus på enkelhet och koppling mot Visma eEkonomi och 
                Visma 500 för fakturering. 
            `,
        },{
            name: 'a-sjo.se',
            url: 'https://a-sjo.se',
            text: `
                Sjöscoutkåren Askims Sjöscoutkårs web och intranät. Informationssidor som redigeras med Google Documents.
            `,
        }];
    }


}
