import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-copy',
  template: '&copy; 2002 - {{ thisYear }} {{ company }}',
})
export class CopyComponent {
  @Input() company: string;
  thisYear: number;

  constructor() {
    this.thisYear = (new Date()).getFullYear();
  }
}
