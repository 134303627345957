import { Component, OnInit } from '@angular/core';
import { Customer } from './customer';
import { CustomerService } from './customer.service';

@Component({
  selector: 'customer-list',
  templateUrl: './customer-list.component.html',
  styles: [`
  `]
})
export class CustomerListComponent implements OnInit {

  customers: Customer[]

  constructor(private customerService: CustomerService) {

  }

  ngOnInit(): void {
    this.customers = this.customerService.getCustomers();
  }

}
