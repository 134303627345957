import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MedcAppComponent } from './medc-app.component';
import { AboutComponent } from './about.component'
import { CustomerListComponent } from './customers/customer-list.component';
import { CustomerItemComponent } from './customers/customer-item.component';
import { ProductListComponent } from './products/product-list.component';
import { CustomerService } from './customers/customer.service';
import { CopyComponent } from './copy.component';

@NgModule({
  declarations: [
    MedcAppComponent,
    AboutComponent,
    CustomerListComponent,
    CustomerItemComponent,
    ProductListComponent,
    CopyComponent,
  ],
  imports: [
    NgbModule,
    BrowserModule
  ],
  providers: [CustomerService],
  bootstrap: [MedcAppComponent]
})
export class AppModule { }
