import { Component } from '@angular/core';

@Component({
  selector: 'about',
  template: `
      <p *ngFor="let paragraph of paragraphs">{{paragraph}}</p>
  `,
})
export class AboutComponent {

  paragraphs: string[] = [
    `Medc AB är ett mjukvaruföretag med stora ambitioner och engagerade medarbetare. 
    Vi erbjuder marknaden kvalificerade systemutvecklare, systemarkitekter och projektledare med många års erfarenhet
    av både små och stora datasystem inom flera branscher. Vi hjälper dig hitta den mest effektiva metoden för ditt
    utvecklingsprojekt baserat på bl.a. komplexitet, plattform, användare och skalbarhet.`,

    `Nöjda kunder skapas med hög kvalitet och hög servicegrad. Långvariga relationer och samarbete ger effektiv utveckling. 
    Vi utvecklar kod som går att underhålla och förändra. Program som lever kväver ständiga förbättringar. 
    Vi kan konsten att bygga nya system, kombinera gamla system med nya och vidareutveckla gamla system, allt efter kundens behov.`,

    `För att komma framåt är vi nyfikna och tar till oss nya ideer och tekniker att jobba med. 
    Det är viktigt att göra om så att man inte fastnar i för gammal teknik och tankesätt.`,
  ]
}
