import { Component, Input } from '@angular/core';
import { Customer } from './customer';

@Component({
    selector: 'customer-item',
    templateUrl: 'customer-item.component.html',
    styles: ['.card { min-height: 200px;}']
  })
export class CustomerItemComponent {

  @Input() customer: Customer;
}
